import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
// Customizable Area End

export const webConfigJSON = require("./config.js");
 
  // Customizable Area Start
export interface Data {
    [key: string]: any;
}

interface FileAttributes {
  file_name: string;
  file_size: number;
  url: string;
}

interface AttachmentAttributes {
  id: number;
  submitted_by: string;
  workspace: string;
  last_modified: string;
  file: FileAttributes;
}

interface Attachment {
  id: string;
  type: string;
  attributes: AttachmentAttributes;
}

interface AttachmentList extends AttachmentAttributes {
  fileType: string
}

interface DataResponse {
  data: Attachment[];
}

  // Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  isOpenDeleteFiles:boolean;
  isSnackbarOpen:boolean;
  fileId: number;
  snackbarContent:string;
  anchorEl2:any;
  fileName:any;
  errorMsg: string;
  loading: boolean; 
  rows: Data[];
  page: number;
  rowsPerPage: number;
  data: Data[];
  attachmentList: AttachmentList[];
  // Customizable Area End
}
interface SS { 
 // Customizable Area Start
  // Customizable Area End
}

export default class DashboardAllFilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  removeFilesApiCallId: string = ""; 
  getAttachmentListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage), 
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      rows: [] as Data[],
      loading: true,
      page: 1,
      rowsPerPage: 9,
      data: [], 
      type: "",
      isOpenDeleteFiles:false,
      fileId: 0,
      isSnackbarOpen:false,
      anchorEl2:null,
      snackbarContent:"",
      fileName:"",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      attachmentList: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAttachments();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId == this.removeFilesApiCallId){
          if(webResponseJson.message){
            this.setState({ isOpenDeleteFiles:false,isSnackbarOpen: true, snackbarContent: "Files have been deleted" })
            this.getAttachments()
          }else{
            this.setState({ isSnackbarOpen: true, snackbarContent: "Cannot deleted files now" })
          }
          this.setState({ loading: false })
        }
        
        if (webApiRequestCallId === this.getAttachmentListApiId) {
          if (webResponseJson.message) {
            this.setState({ loading: false, attachmentList: [] })
          } else {
            const results= (webResponseJson as DataResponse).data.map(list => list.attributes)

            const attachmentList = results.map(list => {
              const fileTypeArray = list.file.file_name.split(".");
              const fileType = fileTypeArray[fileTypeArray.length - 1];
              return { ...list, fileType: fileType } as AttachmentList
            })
            this.setState({ loading: false, attachmentList: attachmentList })
          }
        }
    }
    }
    // Customizable Area End
  }
  // Customizable Area Start 
  handleChangePage = (event: any, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleClose = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })
  handleChangeRowsPerPage = (event: any) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  getAttachments = async () => {
    this.setState({ loading: true })
    const token = await getStorageData("authToken")

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAttachmentListApiId = request.messageId;
    createRequestMessage({
      token,
      requestMessage: request,
      method: "GET",
      endPoint: `/${webConfigJSON.getAttachment}`
    });
  }

  onCloseCancelDialog = () => {
    this.setState({ isOpenDeleteFiles: false })
}
confirmdeleteFiles = ()=>{
  this.setState({isOpenDeleteFiles:true, anchorEl2:null})
}
onCloseAll =()=>{
  this.removeFiles(this.state.fileId)
}
handleClose2 = () => {
  this.setState({anchorEl2:null});
  };
  removeFiles = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token:token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      attachment_id: id
  };

    this.removeFilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_bulk_uploading/delete_attachment`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClick1 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, fileId: number, fileName: string) => {
    this.setState({ anchorEl2: event.currentTarget, fileId, fileName });
  };

  goToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  // Customizable Area End
}
