// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { AIOption } from "./DashboardPreviewFile.web";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";

export const configJSON = require("./config.js");

export type Props =  {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: Array<File>) => void
    classes?: Record<string, string>
    files: any
}

export type aiOptionType = {
    id: string,
    type: string,
    attributes: {
        titles: string
    }
}

interface S {
    files: Array<File>
    currentFile?: File
    isCancel: boolean
    phase: number
    pdf: any
    isSnackbarOpen: boolean
    snackbarContent: string
    nameFile: string
    descriptionFile: string
    fileData: string
    fileFormat: string
    currentTab: number
    listWorkspace: Array<{ id: number; name: string }>
    workspaceChoice: number,
    iconToggle:boolean,
    selectedOptions: Array<{ id: number; name: string }>
    aiAllOptions: Array<{ id: number; name: string }>
    isRegenerate: boolean
    isUploading: boolean
}

interface SS {
  id: string | number;
}

class DashboardPreviewFileController extends BlockComponent<Props, S, SS> {
    getWorkspaceListId: string = "";
    getProcessingParametersId: string = "";
    savePDFid: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            files: [],
            pdf: "",
            isSnackbarOpen: false,
            snackbarContent: "",
            listWorkspace: [],
            currentFile: undefined,
            isCancel: false,
            phase: 1,
            nameFile: "",
            descriptionFile: "",
            fileData: "",
            currentTab: 0,
            workspaceChoice: 0,
            fileFormat: "pdf",
            iconToggle:false,
            selectedOptions: [],
            aiAllOptions: [],
            isRegenerate: false,
            isUploading: false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getWorkspaceListId) {
                    this.setState({ listWorkspace: responseJson })
                }
                if (responseJson.data) {
                    if (apiRequestCallId === this.getProcessingParametersId) {
                        const formatedData = responseJson.data.map((item: aiOptionType) => {
                            return { id: Number(item.id), name: item.attributes.titles }
                        });
                      
                        this.setState({ aiAllOptions: formatedData });
                    }
                  

                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getWorkspaceList()
        this.getProcessingParametersWithAPI()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.files.length !== this.props.files.length) {
            this.setState({ files: this.props.files, currentFile: this.props.files[0] })
        }
    }
  
    buildReportAPI = async (currentFile: File) => {
        const token = await getStorageData("authToken")
      const data = new FormData();
      data.append( "file", currentFile);
        fetch(`${configJSON.urlClientServer}/${configJSON.buildReport}`, { method: 'POST', body: data, headers: {token} })
    }

    handleSavePDF = (pdf: any) => {
        this.setState({ pdf, isRegenerate: false });
    }

    handleClose = () => {
        this.setState({isSnackbarOpen: false})
    }

    checkEnableButton = () => {
        if (this.state.currentTab === 0) {
            if (this.state.fileData !== "" && this.state.nameFile !== "" && this.state.descriptionFile !== "") {
                return true
            } else {
                return false
            }
        } else {
            if (
                this.state.fileData !== "" &&
                this.state.nameFile !== "" &&
                this.state.descriptionFile !== "" &&
                this.state.fileFormat !== "" &&
                this.state.workspaceChoice !== 0 &&
                this.state.pdf !== "" &&
                !this.state.isUploading
            ) {
                return true
            } else {
                return false
            }
        }
    }

    onProceed = () => {
        if (this.state.currentTab === 0) {
            this.setState({ currentTab: 1 })
        } else {
            this.setState({ isUploading: true })
            this.handleCreatedNewFile()
        }
    }
    handleCreatedNewFile = async () => {
        const token = await getStorageData("authToken")
        const data = new FormData();
        data.append("files[]", this.state.pdf);
        data.append("workspace_id", `${this.state.workspaceChoice}`);
        data.append("final_file_name", this.state.nameFile);
        data.append("content_type", this.state.fileFormat);
        const response = await fetch(`${baseURL}/${configJSON.bulkUploading}`, { method: 'POST', body: data, headers: {token} })
        if(response.ok) {
            this.setState({ isCancel: false, isSnackbarOpen: true, snackbarContent: "Files have been successfully uploaded", isUploading: false }, () => {
                setTimeout(() => {
                    this.closeDialog();
                }, 500)
            })
        }
    }

    onClickRegenerate = () => {
        this.setState({ isRegenerate: true })
    }

    resetRegenerate = () => {
        this.setState({ isRegenerate: false })
    }

    onCloseAll = () => {
        this.setState({ isCancel: false, currentTab: 0 }, () => {
            this.closeDialog()
        })
    }

    closeDialog = () => {
        this.setState({
            files: [],
            pdf: "",
            isSnackbarOpen: false,
            snackbarContent: "",
            // listWorkspace: [],
            currentFile: undefined,
            isCancel: false,
            phase: 1,
            nameFile: "",
            descriptionFile: "",
            fileData: "",
            currentTab: 0,
            workspaceChoice: 0,
            fileFormat: "",
            iconToggle:false,
            selectedOptions: [],
            // aiAllOptions: [],
        })
        this.props.onCloseDialog()
    }

    getWorkspaceList = async () => {
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const token = await getStorageData("authToken")
        this.getWorkspaceListId = request.messageId;
        createRequestMessage({
            method: "GET",
            requestMessage: request,
            token,
            endPoint: `${configJSON.getListWorkspace}`
        });
    }

    getProcessingParametersWithAPI = async () => {
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const token = await getStorageData("authToken")
        this.getProcessingParametersId = request.messageId;
        createRequestMessage({
            method: "GET",
            requestMessage: request,
            token,
            endPoint: `${configJSON.getAIOptionList}`
        });
    }

    onBackToPhase1 = () => {
        this.setState({ phase: 1 })
    }

    handleChange = (event: any) => {
        this.setState({ fileData: event.target.value });
    };

    handleChangeAction = (event:Array<AIOption> ) => { 
        this.setState({ selectedOptions: event});
    };

    handleChangeFormat = (event: any) => {
        this.setState({ fileFormat: event.target.value });
    };

    onCloseCancelDialog = () => {
        this.setState({ isCancel: false })
    }

    onOpenCancelDialog = () => {
        this.setState({ isCancel: true })
    }

    onContinueBtn = () => {
        this.setState({ phase: 2 })
    }

    onChangeName = (nameFile: string) => {
        this.setState({ nameFile })
    }

    onChangeDescription = (descriptionFile: string) => {
        if (descriptionFile.trim().length > 0) {
            this.setState({ descriptionFile });
        } else {
            this.setState({ descriptionFile: "" });
        }
    }

    onChangeWorkspace = (workspaceChoice: { id: number; name: string }) => {
        this.setState({ workspaceChoice: workspaceChoice.id })
    }

    onChangeIcon = () => {
        this.setState({ iconToggle: !this.state.iconToggle });
    }

    handleSelectAll = (checked: boolean) => {
        if (checked) {
          this.setState({ selectedOptions: [...this.state.aiAllOptions] }, () => {
            this.handleChangeAction(this.state.selectedOptions);
          });
        } else {
          this.setState({ selectedOptions: [] }, () => {
            this.handleChangeAction(this.state.selectedOptions);
          });
        }
      };

    currentTabChange = (num: number) => {
        this.setState({ currentTab: num });
    } 
}

export default DashboardPreviewFileController;
// Customizable Area End
