import React from "react";
// Customizable Area Start
import {
  Box, Typography, AppBar, Toolbar, createTheme, ThemeProvider, Button,
  Grid, styled,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteIcon } from "../../accountgroups/src/assets";
import ConfirmationDialog from '../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import TableComponent from "../../../components/src/DashboardComponent/TableComponent.web";
import UserProfileDialog from "../../user-profile-basic/src/UserProfileDialog.web";
import { upload } from './assets';
import BulkUploadDashboardDialog from "./BulkUploadDashboardDialog.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import UserProfileBasicBlockWeb from "../../../../packages/blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import { Alert } from "@material-ui/lab";
import DashboardPreviewFile from "./DashboardPreviewFile.web";
import Loader from "../../../../packages/components/src/Loader.web";
import { downloadIcon } from "../../../../packages/blocks/termsconditions/src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: '#E2E8F0',
    },
  },
  shape: {
    borderRadius: 16,
  },
  overrides: {
    MuiAvatar: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiTableCell: {
      alignRight: {
        textAlign: "start"
      },
      root: {
        padding: "15px 16px 12px 28px"
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px"
      }
    }
  },
});
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getIcon = (fileName: string) => {
    const listName = fileName.split('.')
    const extension = listName[listName.length - 1]

    return (
      <div style={{ position: "relative" }}>
        <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyles.iconRecent}>
          <g clipPath="url(#clip0_23865_35562)">
            <path fillRule="evenodd" clipRule="evenodd" d="M89.667 27.1137C89.6768 10.4163 79.8405 0.568566 63.1432 0.558779L26.8272 0.537492C10.0879 0.52768 0.221471 10.3639 0.211683 27.0612L0.190347 44.7946C0.180574 61.4685 6.3068 71.3141 23.0088 71.3239L32.3421 71.3294C35.6928 71.3267 38.8484 69.7559 40.8566 67.0737L45.1206 61.4109C47.1382 58.7334 50.2938 57.1579 53.6444 57.1552L66.8511 57.163C83.5904 57.1728 89.7741 67.3217 89.7841 50.2837L89.667 27.1137Z" fill="#E3DEFF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M89.667 63.4387C89.6768 80.1361 79.8405 89.9838 63.1432 89.9936L26.8272 90.0149C10.0879 90.0247 0.221471 80.1885 0.211683 63.4912L0.190347 27.0912C0.180574 10.4172 6.3068 0.571601 23.0088 0.561811L32.3421 0.55634C35.6928 0.559043 38.8484 2.12986 40.8566 4.81202L45.1206 10.4749C47.1382 13.1523 50.2938 14.7278 53.6444 14.7305L66.8511 14.7228C83.5904 14.713 89.7741 23.2307 89.7841 40.2687L89.667 63.4387Z" fill="#684EF3" />
            <path d="M24.6215 54.0521L65.3848 54.0282" stroke="#F6F5F9" strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.6215 37.7191L65.3848 37.6952" stroke="#F6F5F9" strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_23865_35562">
              <rect width="89.6798" height="89.5078" fill="white" transform="translate(0.133789 0.522461) rotate(-0.0335844)" />
            </clipPath>
          </defs>
        </svg>
        <span style={webStyles.extension}>{extension.toUpperCase()}</span>
       
      </div>
    )
  }
  renderSearch = () => {
    return (
      <TextField
          className={this.props.classes?.searchInput}
          data-test-id="searchInput"
          value={this.state.searchText}
          onChange={this.onChangeSearchInput}
          placeholder={webConfigJSON.search}
          InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 16.147C9.77498 16.1466 11.4988 15.5524 12.897 14.459L17.293 18.855L18.707 17.441L14.311 13.045C15.405 11.6466 15.9996 9.92241 16 8.14697C16 3.73597 12.411 0.146973 8 0.146973C3.589 0.146973 0 3.73597 0 8.14697C0 12.558 3.589 16.147 8 16.147ZM8 2.14697C11.309 2.14697 14 4.83797 14 8.14697C14 11.456 11.309 14.147 8 14.147C4.691 14.147 2 11.456 2 8.14697C2 4.83797 4.691 2.14697 8 2.14697Z" fill="#94A3B8" />
                      </svg>
                  </InputAdornment>
              ),
              disableUnderline: true,
              className: this.props.classes?.searchStyle
          }}
      />
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#E2E8F0", height: "100vh" }}>
          <div style={{ position: "sticky" }}>
            <NavigationMenu navigation={this.props.navigation} id="dashboard" showProfileFunc={this.toShowDashboardProfile} />
          </div>
          {this.state.showProfile ? (<UserProfileBasicBlockWeb navigation={this.props.navigation} id="" />) : (<div style={{ overflowY: "auto", width: "100%" }}>
            <div style={{ flexGrow: 1, }}>
              <ThemeProvider theme={theme}>
                <AppBar position="static">
                  <Toolbar style={webStyles.appbar}>
                    <Typography style={webStyles.title}>{webConfigJSON.dashboard}</Typography>
                    <div style={webStyles.viewSearch}>
                      {this.renderSearch()}
                      <svg style={webStyles.notification} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 13.586V10C19 6.783 16.815 4.073 13.855 3.258C13.562 2.52 12.846 2 12 2C11.154 2 10.438 2.52 10.145 3.258C7.185 4.074 5 6.783 5 10V13.586L3.293 15.293C3.19996 15.3857 3.12617 15.4959 3.07589 15.6172C3.0256 15.7386 2.99981 15.8687 3 16V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V16C21.0002 15.8687 20.9744 15.7386 20.9241 15.6172C20.8738 15.4959 20.8 15.3857 20.707 15.293L19 13.586ZM19 17H5V16.414L6.707 14.707C6.80004 14.6143 6.87383 14.5041 6.92412 14.3828C6.9744 14.2614 7.00019 14.1313 7 14V10C7 7.243 9.243 5 12 5C14.757 5 17 7.243 17 10V14C17 14.266 17.105 14.52 17.293 14.707L19 16.414V17ZM12 22C12.6193 22.0008 13.2235 21.8086 13.7285 21.4502C14.2335 21.0917 14.6143 20.5849 14.818 20H9.182C9.38566 20.5849 9.76648 21.0917 10.2715 21.4502C10.7765 21.8086 11.3807 22.0008 12 22Z" fill="#14101E"/>
                      </svg>
                    </div>
                  </Toolbar>
                </AppBar>
              </ThemeProvider>
              <Grid container style={webStyles.pdfContainer}>
                {
                  this.state.listAttachment.length === 0
                    ? <div />
                    : (
                      <Grid
                        container item xl={7} lg={7} md={7} sm={7} xs={7}
                        style={webStyles.viewRecentFile}
                      >
                        <Typography style={{ ...webStyles.filesCs, ...{ marginBottom: "23px" } }}>Your recent files</Typography>
                        <Grid container item style={webStyles.uploadFiles}>
                          {
                            this.state.listAttachment.slice(0, 3).map((item, index) => {
                              return (
                                <Grid
                              data-test-id={`attachment_${index}`}
                              key={`attachment_${index}`}
                              container
                              item
                              xl={4}
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              style={{ paddingRight: "20px", width: "unset", cursor: "pointer", position: "relative" }}
                            >
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={webStyles.uploadFileContainer}

                              >
                                                                        <IconButton
                                                                         style={{ position: 'absolute', top: 0, right: 7 }}
          data-test-id={`member-${item.id}`}
          onClick={(event) => this.handleClick1(event, item.id,item.attributes.file.file_name, item.attributes.file.url)}
        >
          <MoreVertIcon />
        </IconButton>
                              
                                
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }} onClick={() => this.onChooseFile(item)}>
                                  {this.getIcon(item.attributes.file.file_name)}
                                </div>
                                <Typography style={webStyles.nameFiles}>
                                  {item.attributes.file.file_name}
                                </Typography>
                                <Typography style={webStyles.fileEdited}>
                                  {moment(new Date(item.attributes.last_modified)).fromNow()}
                                </Typography>
                              </Grid>
                              <Menu anchorEl={this.state.anchorEl2}
      open={Boolean(this.state.anchorEl2)} onClose={this.handleClose2}
      PaperProps={{ elevation: 0, style: { boxShadow: 'none', border: "1px solid #E2E8F0"
        }
      }}
    >
          <MenuItem 
          onClick={this.confirmdeleteFiles}>
            <ListItemIcon>
              <img
                src={deleteIcon} alt="Remove File"
                style={{ height: "20px",
                 width: "18px" } as React.CSSProperties}
              />
            </ListItemIcon>
            <ListItemText
              primary="Remove File" style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as React.CSSProperties}
            />
          </MenuItem> 
                                    <MenuItem onClick={this.downloadFile}>
                                      <ListItemIcon>
                                        <img
                                          src={downloadIcon} alt="Download File"
                                          style={{ height: "20px",
                                          width: "18px" } as React.CSSProperties}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary="Download File" style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as React.CSSProperties}
                                      />
                                    </MenuItem> 
                                  </Menu>
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                      </Grid>
                    )
                }
                <Grid item
                  xl={this.state.listAttachment.length === 0 ? 12 : 5}
                  lg={this.state.listAttachment.length === 0 ? 12 : 5}
                  md={this.state.listAttachment.length === 0 ? 12 : 5}
                  sm={this.state.listAttachment.length === 0 ? 12 : 5}
                  xs={this.state.listAttachment.length === 0 ? 12 : 5}
                >
                  <Typography style={{ ...webStyles.filesCs, ...{ marginBottom: "23px" } }}>Upload Files</Typography>
                  <Box style={webStyles.uploadContainer}>
                    <img src={upload} />
                    <Typography style={webStyles.draganddropText}>
                      Drag and drop your files or
                    </Typography>
                    <Button data-test-id="open-upload-dialog" style={webStyles.browseButton} onClick={this.openUploadDialog}>
                      Browse
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box style={webStyles.tableContainer}>
                <Box style={webStyles.containerBox}>
                  <Typography style={webStyles.filesCs}>Files</Typography>
                  <Typography style={webStyles.seeAll} data-test-id="btnFile" onClick={this.handleAllFiles}>View All</Typography>
                </Box>
                <ThemeProvider theme={theme}>
                  <TableComponent
                    arrayData={this.state.listAttachment.slice(0, 5)}
                    onChooseFile={this.onChooseFile}
                    onChooseMoreOption={this.handleClick1}
                  />
                </ThemeProvider>
              </Box>
            </div>
          </div>)}

          <BulkUploadDashboardDialog
            id="BulkUploadDashboardDialog"
            isDialogOpen={this.state.isOpenUploadDialog}
            onCloseDialog={this.onCloseUploadDialog}
            classes={this.props?.classes}
          />
          <DashboardPreviewFile
            id="DashboardPreviewFile"
            isDialogOpen={this.state.isOpenPreviewDialog}
            onCloseDialog={this.onClosePreviewDialog}
            classes={this.props?.classes}
            files={this.state.dataFiles?.files ?? []}
          />
          <UserProfileDialog
            id="UserProfileDialog"
            isDialogOpen={this.state.isNewAccount}
            onCloseDialog={this.onCloseUserProfileCloseDialog}
            classes={this.props?.classes}
          />
          <Loader loading={this.state.loading} />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.isSnackbarOpen} autoHideDuration={3000} onClose={this.handleClose}
          >
              <Alert icon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.4933 7.28613L11.9575 10.8211L8.42247 7.28613L7.24414 8.46447L10.7791 11.9995L7.24414 15.5345L8.42247 16.7128L11.9575 13.1778L15.4933 16.7128L16.6716 15.5345L13.1366 11.9995L16.6716 8.46447L15.4933 7.28613Z" fill="white"/>
                    </svg>
                  }
                  style={
                    webStyles.viewAlert}
              >
                  {this.state.snackbarContent}
              </Alert>
          </Snackbar>
          <ConfirmationDialog
            id="ConfirmationDialog"
            isDialogOpen={this.state.isOpenDeleteFiles} onCloseDialog={this.onCloseCancelDialog}
            title={webConfigJSON.deleteFiles}
            description={webConfigJSON.deleteFliesDescription1 + "<span style='font-weight: bold'>"+this.state.fileName+"</span>" + webConfigJSON.deleteFilesDescription2}  titleCancelButton={webConfigJSON.cancelDelete}
            titleOkButton={webConfigJSON.confirmDelete}  onOkCloseDialog={this.onCloseAll}
            checkConfirmationTitle=""
          />
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const DetailedBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column"
});

const Customimage = styled('img')({
  width: "156px",
  height: "156px",
  borderRadius: "100%",
  '&:hover': {
    filter: "brightness(0.8)",
    '&::after': {
      "content": 'Edit Picture'
    }
  }
});


const CustomPopup = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  gap: "10px",
  '&:hover': {
    background: "lightgrey",
  }
});

const CustomRemove = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  gap: "10px",
  width: "100%",
  '&:hover': {
    background: "lightgrey",
  }
});


const CustomInput = styled('input')({
  height: "44px",
  width: "212%",
  padding: "4px 9px",
  borderRadius: "8px",
  border: "1px solid #CDD7E6",

})

const webStyles = {
  viewAlert: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    padding: "8px",
    borderRadius: "16px",
    color: "#fff",
    backgroundColor: "#33BC55"
  },
  appbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 40px"
  } as React.CSSProperties,
  viewSearch: {
    display: "flex",
    gap: "18px",
    alignItems: "center"
  } as React.CSSProperties,
  extension: {
    backgroundColor: "#fff",
    color: "#684EF3",
    borderRadius: "10px",
    padding: "7px 10px",
    position: "absolute",
    bottom: 0,
    right: 0
  } as React.CSSProperties,
  viewRecentFile: {
    width: "unset",
    display: "flex",
    flexDirection: "column"
  } as React.CSSProperties,
  iconRecent: {
    marginBottom: "24px",
    width: "90px",
    height: "90px",
  },
  notification:{
    height: "24px",
    width: "24px" 
  },
  seeAll: {
    cursor: "pointer",
    color: "#684EF3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "DM Sans"
  },
  filesCs: {
    color: "#14101E",
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: "bold",
    fontFamily: "DM Sans"
  },
  containerBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "23px"
  },
  uploadFileContainer: {
    borderRadius: "16px",
    backgroundColor: "#fff",
    padding: "38px 10px 20px 10px",
    height: "220px",
    width: "unset",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  } as React.CSSProperties,
  nameFiles: {
    marginLeft: "6px",
    fontWeight: "bold",
    fontFamily: "DM sans",
    fontSize: "14px",
    lineHeight: "21px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "80%",
    whiteSpace: "nowrap",
  },
  fileEdited: {
    marginLeft: "50px",
    fontWeight: 400,
    fontFamily: "DM sans",
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%"
  },
  uploadFiles: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    width: "unset"
  },
  draganddropText: {
    padding: "15px 0px",
    fontWeight: 400,
    fontFamily: "DM sans",
    fontSize: "14px"
  } as const,
  browseButton: {
    fontFamily: "DM sans",
    backgroundColor: "#684EF3",
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    textTransform: "none",
    padding: "4px 20px",
    borderRadius: "8px"
  } as const,
  uploadContainer: {
    backgroundColor: "#EAE6FF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "212px",
    border: "2px dashed #9B93F6",
    borderRadius: "16px"
  } as const,
  tableContainer: {
    flex: "1",
    margin: "24px 40px",
  },
  pdfContainer: {
    maxHeight: "272px",
    height: "100%",
    margin: "24px 40px",
    width: "unset"
  },
  title: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "33.6px",
    color: "#14101E",
    fontFamily: "DM Sans"
  },
};

const useStyles: Record<string, CSSProperties> = {
  searchInput: {
      border: "1px solid #E2E8F0",
      width: "304px",
      backgroundColor: "#fff",
      paddingLeft: "12px",
      borderRadius: "8px",
      paddingBottom: "10px",
      paddingTop: "10px",
  },
  searchStyle: {
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: "DM Sans",
      lineHeight: '24px',
      color: "#30353B",
  },
  inputText: {
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "1px solid #E2E8F0",
      paddingLeft: "12px",
      backgroundColor: "#fff",
      borderRadius: "8px",
  },
  inputTextMultiline: {
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "1px solid #E2E8F0",
      paddingLeft: "12px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      height: "135px"
  },
  middleside: {
    width: '600px',
    position: "sticky",
    padding: '24px 32px 0px 32px',
    borderRight: '1px solid #E2E8F0',
    backgroundColor: "#E2E8F0",
    '@media (max-width: 600px)': {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #E2E8F0',
    }
  },
  rightSide: {
    padding: '24px 32px',
    overflow: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "40vw"
  },
  body: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    overflow: "hidden",
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      padding: '0px 16px',
    }
  },
  leftSide: {
    width: '240px',
    position: "sticky",
    height: '100%',
    padding: '24px 20px 20px 20px',
    borderRight: '1px solid #E2E8F0',
    backgroundColor: "#f6f5f9",
    '@media (max-width: 600px)': {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #E2E8F0',
    }
  },
  customdropdown: {
    borderRadius: "8px",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#fff",
    '& .MuiFilledInput-input': {
      padding: "12px",
      height: "fit-content"
    },
    '&::before, &::after': {
      borderBottom: "none !important"
    },
    '&:hover': {
      textDecoration: "none !important"
    }
  },
  dropdownitem: {
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    cursor: "pointer",
  },
  openMenuButton: {
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAE6FF",
    }
  },
  tab: {
      backgroundColor: "#fff",
      padding: "6px",
      border: "1px solid #D9D1EF",
      borderRadius: "12px",
      minHeight: "unset",
  },
  chosenTab: {
      backgroundColor: "#EDEAFF",
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      padding: "4px 12px",
      color: "#684EF3",
      borderRadius: "6px",
      textTransform: "capitalize",
      minWidth: "unset",
      minHeight: "unset",
  },
  normalTab: {
      backgroundColor: "#fff",
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      padding: "4px 12px",
      color: "#5E6671",
      borderRadius: "6px",
      textTransform: "capitalize",
      minWidth: "unset",
      minHeight: "unset",
  },
}
export default withStyles(useStyles)(Dashboard);
export { useStyles };
// Customizable Area End
